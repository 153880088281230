import React, { useRef } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import elfonzenumbers from '../../../static/img/elfonze_numbers_1.jpg';


function ShowcaseMasanory() {

//     const slider = useRef(null);

//   let settings = {
//       infinite: true,
//       speed: 1000,
//       slidesToShow: 1,
//       slidesToScroll: 1,
//       swipeToSlide: true,
//       autoplay: true,
//       autoplaySpeed: 5000,
//       arrow:true,
//   }


  return (
    <div className='container testimonials custom_banner_slider'>
        <div className='row wow fadeInUp'>
            <div className=''>
                <img src={elfonzenumbers} alt="Elfonze Numbers" loading='lazy'/>
            </div>
        </div>
    </div>
  )
}

export default ShowcaseMasanory